import React from 'react'
import { IBlock } from "../../framework/src/IBlock";
import { Box } from '@mui/material';
import {
  layout_Corners_Dashboard,
  search_Icon,
  natasha_Icon,
  retouch_Graph,
  group_Logo,
  logout_icon,
  dashboard_Icon,
  explorer_Icon,
  trial_Icon,
  Helios,
  HeliosGray,
  star_border,
  upArrow,
  downArrow
} from "./Assets"

import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

import { BlockComponent } from "../../framework/src/BlockComponent";
import { DashboardCardData } from '../../blocks/dashboard/src/DashboardController.web';
import MergeEngineUtilities from '../../blocks/utilities/src/MergeEngineUtilities';


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

type FavoriteDashboardData = {
  id: string
  type: string
  attributes: {
    name: string
    description: string
    popularity: string
    url: string
    url_type: string
    card_section_id: number
    created_at: string
    updated_at: string
  }
}

type DashboardFavourite = {
  name: string;
  dashboardCard: DashboardCardData[];
  favourites: Map<string, string>;
}
interface Dashboard_card {
  id: number,
  name: string,
  description: string,
  popularity: string,
  url: string,
  url_type: string,
  card_section_id: number,
  created_at?: string,
  updated_at?: string,
  fav_status: boolean,
}
export interface ApiDataType {
  data: [

    {
      id: string;
      type: string;
      attributes: {
        name: string;
        created_at: string;
        updated_at: string;
        card_section_id: number,
        id: number;

        description: string;
        popularity: string;
        url: string;
        url_type: string;


        dashboard_cards: Dashboard_card[];
      }
    },
  ],
  meta: {
    message: string;
  }
}

type TrialData = {
  id: string
  type: string
  attributes: {
    id: number
    name: string
    url: string
    created_at: string
    updated_at: string
    icon_url: string
    groups_info: any[]
  }
}

export interface ErrorResponse {
  errors: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: {
    push: (path: string) => void;
    length: number;
    location: any;
    action: string
  }
  url: string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardSidebarTrialsData: TrialData[];
  sidebarPanelData: string[];
  showLogoutBtn: boolean;
  isClick: any;
  iframeData: { name: string, url: string } | null;
  userInfo: any;
  rendering: boolean;
  favouriteData: FavoriteDashboardData[];
  expandFavourite: boolean;
  isFavouriteApiProcessed: boolean;
  isUserApiProcessed: boolean;
  isTrialApiProcessed: boolean;
  trialData: any;
  trialName: any;
  historyLength: number;
  currentPath: string;
  trials: any;
  // Customizable Area End
}
interface SS { }
export default class Sidebar extends BlockComponent<Props, S, SS>{

  getDashboardSidebarTrialsAPIId: string = "";
  getUserInfoAPIId: string = "";
  logoutAPIId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];


    this.state = {
      dashboardSidebarTrialsData: [],
      sidebarPanelData: [],
      showLogoutBtn: false,
      isClick: window.location.pathname,
      iframeData: null,
      userInfo: {},
      rendering: false,
      expandFavourite: true,
      favouriteData: [],
      isUserApiProcessed: false,
      isFavouriteApiProcessed: false,
      isTrialApiProcessed: false,
      trialData: [],
      trialName: [],
      historyLength: this.props.history.length,
      currentPath: "",
      trials: {}
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({currentPath: this.props.history.location.pathname});
    
    // const storedTrialData = localStorage.getItem('trialName');
    if (window.location.pathname === '/Trials') {
      this.setState({ isClick: this.state.trialName });
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let token: any = params.get('t');

    const localStorageUserToken = localStorage.getItem("userInfo")
    const parseLocalStorage = JSON.parse(localStorageUserToken ?? "{}") || {}
    if (token !== null && token != parseLocalStorage.session_token) {
      parseLocalStorage.session_token = token;
      localStorage.setItem("userInfo", JSON.stringify(parseLocalStorage))
    }

    if ((parseLocalStorage?.session_token ?? "") === "") {
      const path = window.location.pathname;
      localStorage.setItem("redirecturl", path)
      this.changePath("/")
    }

    if (window.location.pathname === '/Dashboard' && token) {
      this.getUserInfo(token)
    } else {
      const localStorageUserInfo: any = (localStorage.getItem("userInfo"))
      const userInformation = JSON.parse(localStorageUserInfo);
      const token = userInformation.session_token
      JSON.parse((localStorageUserInfo)) ? this.setState({ userInfo: userInformation }) : this.changePath("/")
      this.getDashboardSidebarTrials(token)
      this.getDashbaordFavorites(token)
    }
    const redirectpath = localStorage.getItem("redirecturl")
    this.redirectAfterLogin(redirectpath!)
    const localStorageUserTrialInfo: any = localStorage.getItem("userTrialInfo")
    if (localStorageUserTrialInfo) {
      this.setState({ dashboardSidebarTrialsData: JSON.parse(localStorageUserTrialInfo) })
    }
    const panelsAllowed = localStorage.getItem("panels_allowed")
    if (panelsAllowed) {
      this.setState({ sidebarPanelData: JSON.parse(panelsAllowed) })
    }
    this.redirect(JSON.parse(panelsAllowed ?? '[]'), JSON.parse(localStorageUserTrialInfo ?? '[]'))
    // Customizable Area End
  }
  redirectAfterLogin (redirectpath: string){
    //redirect to carddata

    if(redirectpath !== "" && redirectpath !== null){
      localStorage.setItem("redirecturl", "");
      this.changePath(redirectpath)
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.props.history.action !== 'POP') return;
    if(this.state.currentPath !== this.props.history.location.pathname){
      let path = this.props.history.location.pathname;
      switch(this.props.history.location.pathname){
        case '/Explorer':
          this.setState({isClick: 'Explorer'})
        break;
        case '/Trials':
          const localName = localStorage.getItem('trialName')
          this.setState({isClick: localName})
        break;
        default:
          this.setState({isClick: this.props.history.location.pathname})
        break;
      }
      this.setState({currentPath: path});
      
    }
  
  }

  getCurrentPath() {
    return window.location.pathname
  }

  changePath(path: string, search: string = "") {
    this.setState({currentPath: path})
    this.props.history.push(path)
  }

  handleChange() {
    this.changePath("/Natasha")
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && webResponseJson.error) {
        this.isInvalidToken(webResponseJson.error)
        if (webApiRequestCallId === this.getUserInfoAPIId) {
          this.changePath('/')
        };
      }

      if (this.isValidResponse(webResponseJson || webErrorReponse)) {
        this.responseSucessCallBack(webApiRequestCallId, webResponseJson);
      }

      if (webApiRequestCallId === this.logoutAPIId) {
        localStorage.clear();
        this.changePath("/")
      };
    }
    // Customizable Area End
  }

  isInvalidToken = (errorResponse: string) => {
    if (errorResponse === "Account not found") {
      localStorage.removeItem("userInfo")
      this.changePath("/")
    }
  }

  isValidResponse = (responseJson: ApiDataType) => {
    return responseJson && responseJson.data;
  };

  inValidResponse = (responseJson: ErrorResponse) => {
    return responseJson && responseJson.errors;
  };

  responseSucessCallBack = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getDashboardSidebarTrialsAPIId) {
      !this.state.isTrialApiProcessed? this.successTrialsSidebarCallback(responseJson) : this.setState({isTrialApiProcessed: false});
    };
    if (apiRequestCallId === this.getUserInfoAPIId) {
      !this.state.isUserApiProcessed?this.sucessUserInfoCallback(responseJson):this.setState({isUserApiProcessed: false})
    };
    if (apiRequestCallId === 'dashboard-favourite-sidebar') {
      !this.state.isFavouriteApiProcessed?this.successFavouriteSidebarCallback(responseJson):this.setState({isFavouriteApiProcessed: false});
    }
  };

  getDashbaordFavorites = (token: string) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    message.messageId = 'dashboard-favourite-sidebar'
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_content_management/favourite_dashboard_cards',
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET',
    )
    const header = {
      'Content-Type': 'application/json',
      'token': token,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    this.send(message)
  }

  private successTrialsSidebarCallback(responseJson: any) {
    this.setState({isTrialApiProcessed: true});
    localStorage.setItem("userTrialInfo", JSON.stringify(responseJson?.data));
    localStorage.setItem("panels_allowed", JSON.stringify(responseJson?.panels_allowed));
    localStorage.setItem("explorerToken", JSON.stringify(responseJson?.gooddata_auth_token));
    this.setState({ sidebarPanelData: responseJson.panels_allowed });
    this.setState({ dashboardSidebarTrialsData: responseJson.data });
    this.redirect(responseJson?.panels_allowed ?? [], responseJson?.data ?? []);
  }

  private successFavouriteSidebarCallback(responseJson: any) {
    this.setState({isFavouriteApiProcessed: true})
    let data = responseJson.data as FavoriteDashboardData[];
    data.sort((a, b) => {
      const nameA = a.attributes.name.trim().toLowerCase();
      const nameB = b.attributes.name.trim().toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
    this.setState(prev => ({ ...prev, favouriteData: data }));
  }

  private sucessUserInfoCallback(responseJson: any) {
    this.setState({isUserApiProcessed: true})
    this.setState({ userInfo: responseJson.data.attributes });
    localStorage.setItem("userInfo", JSON.stringify(responseJson.data.attributes));
    this.passDataToDashboard(responseJson.data.attributes);
    this.getDashboardSidebarTrials(responseJson.data.attributes.session_token);
    console.log("CALLED AFTER GETUSERINFO");
    this.getDashbaordFavorites(responseJson.data.attributes.session_token);
    //redirect to carddata
  }

  redirect(panels_allowed: string[], data: any[]) {
    if (this.getCurrentPath() === '/Whiteboard') {
      return
    }
    let sidebarOptionsDB: (() => void)[] = []
    let flag = false
    for (const panel of panels_allowed) {
      switch (panel) {
        case 'All':
          flag = flag || this.getCurrentPath() === '/Dashboard'
          flag = flag || this.getCurrentPath() === '/Natasha'
          flag = flag || this.getCurrentPath() === '/Analytics'
          flag = flag || this.getCurrentPath().includes('/DashboardCard')
        case 'Dashboards':
          sidebarOptionsDB.push(() => this.changePath('/Dashboard'))
          flag = flag || this.getCurrentPath() === '/Dashboard'
          flag = flag || this.getCurrentPath().includes('/DashboardCard')
          break;
        case 'Natasha':
          sidebarOptionsDB.push(() => this.changePath('/Natasha'))
          flag = flag || this.getCurrentPath() === '/Natasha'
          break;
        case 'Analytics2':
        case 'Analytics':
          sidebarOptionsDB.push(() => this.changePath('/Analytics'))
          flag = flag || this.getCurrentPath() === '/Analytics'
          break;
      }
    }
    for (const trial of data) {
      this.setState((prev)=>{ return {trials: {...prev.trials, [trial.attributes.name.trim()]: trial}}})
      if (trial.attributes.name === 'Explorer') {
        sidebarOptionsDB.push(() => this.handleExplorerClick(trial))
        flag = flag || this.getCurrentPath() === '/Explorer'
      } else {
        sidebarOptionsDB.push(() => this.handleTrialClick(trial))
        flag = flag || (this.getCurrentPath() === '/Trials'
          && (localStorage.getItem('trialName') ?? '') === trial.attributes.name)
      }
    }
    if (!flag && sidebarOptionsDB.length > 0) {
      sidebarOptionsDB[0]()
    }
  }

  getDashboardSidebarTrials(token: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDashboardSidebarTrialsAPIId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_trial/trials"
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getUserInfo(token: string) {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserInfoAPIId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_user/user_info`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }


  logout(token: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logoutAPIId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/aaa/backchannel_logout`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST");
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  handleStarredClick = (data: FavoriteDashboardData) => {
    return () => {
      localStorage.setItem('trialData', data.attributes.url);
      localStorage.setItem('trialName', data.attributes.name);
      this.setState((prev)=>({trialData: [...prev.trialData, data.attributes.url], trialName: [...prev.trialName, data.attributes.name]}));
      this.changePath('/DashboardCard');
    }
  }

  handleLogout = () => {
    this.analyticTrack("logout")
    localStorage.setItem('IframeClicked', 'false')
    this.logout(this.state.userInfo?.session_token)
  }
  dashboardClick = () => {
    return (
      this.state.isClick === '/Dashboard' ? layout_Corners_Dashboard : dashboard_Icon
    )
  }
  explorerClick = () => {
    return (
      this.state.isClick === '/Explorer' ? explorer_Icon : search_Icon
    )
  }

  AnalyticsClick = () => {
    return (
      this.state.isClick === '/Analytics' ? Helios : HeliosGray
    )
  }

  showIframe = (data: { name: string, url: string }) => {
    this.setState({
      iframeData: data,
      isClick: data.name
    })
  };
  renderIframeData = () => {
    return (
      <div style={{ paddingLeft: '225px', paddingRight: '24px', marginTop: 68 }}>

        {this.state.iframeData ? <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <span style={{ fontSize: 32, fontWeight: "500", fontStyle: 'normal', marginLeft: 40, fontFamily: "Rubik" }}>{this.state.iframeData.name}</span>
          <iframe src={this.state.iframeData.url} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', margin: "0px 50px", marginTop: "38px", width: '1100px', height: '100vh', border: 'none' }}></iframe></div>
          :
          null
        }
      </div>
    )
  }
  handleTrialClick = (trial: any) => {
    const url = trial.attributes.url;
    localStorage.setItem('trialData', url);
    localStorage.setItem('trialName', trial.attributes.name);
    this.setState({ isClick: trial.attributes.name }), this.changePath("/Trials")
  }

  handleExplorerClick = (trial: any) => {
    const url = trial.attributes.url;
    localStorage.setItem('trialData', url);
    localStorage.setItem('trialName', trial.attributes.name);
    this.setState({ isClick: trial.attributes.name })
    this.changePath("/Explorer")
  }

  passDataToDashboard = (userInfo: any) => {
    const msg: Message = new Message(getName(MessageEnum.CallDashboardMessage));
    msg.addData(
      getName(MessageEnum.CallDashboardDataMessage),
      userInfo
    );

    this.send(msg);
  }

  analyticTrack(trackName: string) {
    const sidebarATMsg: Message = new Message(
      getName(MessageEnum.AnalyticsTrackMessage)
    );
    sidebarATMsg.addData(getName(MessageEnum.AnalyticsTrackMessageName), trackName);
    this.send(sidebarATMsg);
  }

  expandFav = () => {
    this.setState((prevState) => ({ expandFavourite: !prevState.expandFavourite }))
    this.analyticTrack('starred')
  }
  render() {
    return (<div style={{
      backgroundColor: '#f2f2f2',
      flexDirection: "row",
      display: 'flex'
    }}>
      <div style={{ maxWidth: 225, padding: "10px 15px", backgroundColor: "#fff", position: 'fixed', height: '100vh', boxShadow: '8px 0px 24px 0px #00000008', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        <img style={{ width: 170, height: 50, marginTop: 18, objectFit: 'contain', alignSelf: "center" }} src={group_Logo} alt="Group Logo" />
        <div style={{ width: 172, height: 300, alignSelf: "center", marginTop: 20, flex: 1, overflowY: 'auto' }}>
          {this.state.sidebarPanelData.map((item, index: number) => {
            return <ul key={index+item} style={webStyles.menuBarStyleWrapperTop}>
              {['All', 'Dashboards'].includes(item) && <li style={webStyles.menuBarStyle} onClick={() => { this.analyticTrack("dashboard"); this.setState({ isClick: '/Dashboard' }), this.changePath("/Dashboard"); }}>
                <img src={this.dashboardClick()}
                  style={{ width: 32, height: 32, objectFit: 'contain' }} />
                <span style={{ marginLeft: 12, fontFamily: 'Rubik', fontSize: 14, fontWeight: "500", color: this.state.isClick === '/Dashboard' ? '#6200EA' : '#676B7E', cursor: 'pointer' }}>Dashboard</span>
              </li>}
              {['All', 'Natasha'].includes(item) && <li style={webStyles.menuBarStyle} onClick={() => { this.analyticTrack("natasha"); this.setState({ isClick: '/Natasha' }), this.changePath("/Natasha"); }}>
                <img src={natasha_Icon}
                  style={{ width: 32, height: 32, objectFit: 'contain' }} />
                <span style={{ marginLeft: 12, fontFamily: 'Rubik', fontSize: 14, fontWeight: "500", color: this.state.isClick === '/Natasha' ? '#6200EA' : '#676B7E', cursor: 'pointer' }}>Natasha</span>
              </li>}
            </ul>
          })}

          {this.state?.dashboardSidebarTrialsData ? (this.state.dashboardSidebarTrialsData.map((trial, index: number) => {
            const attrName = (trial.attributes.name as string).toLowerCase()
            return <>
              {
                trial.attributes.name === 'Explorer' ? (
                  <ul style={webStyles.menuBarStyleWrapperBottom} onClick={() => { this.analyticTrack("explorer"); this.handleExplorerClick(trial); }}>
                    <li style={webStyles.menuBarStyle}>
                      <img src={this.state.isClick === trial.attributes.name? explorer_Icon : search_Icon}
                        style={{ width: 32, height: 32, objectFit: 'contain' }} />
                      <span style={{ marginLeft: 12, fontFamily: 'Rubik', fontSize: 14, fontWeight: "500", cursor: 'pointer', color: this.state.isClick === trial.attributes.name ? '#6200EA' : '#676B7E' }}>{trial.attributes.name}</span>
                    </li>
                  </ul>
                ) :
                  <ul style={webStyles.menuBarStyleWrapperBottom} onClick={() => { this.analyticTrack(attrName); this.handleTrialClick(trial); }}>
                    <li style={webStyles.menuBarStyle}>
                      <img src={this.state.isClick === trial.attributes.name ? trial_Icon : retouch_Graph}
                        style={{ width: 32, height: 32, objectFit: 'contain'}} />
                      <span style={{ marginLeft: 12, fontFamily: 'Rubik', fontSize: 14, fontWeight: "500", cursor: 'pointer', color: this.state.isClick === trial.attributes.name ? '#6200EA' : '#676B7E' }}>{trial.attributes.name}</span>
                    </li>
                  </ul>
              }
            </>
          })) : ""}

          {
            this.state.sidebarPanelData
              .filter(item => ['All', 'Analytics2', 'Analytics'].includes(item))
              .length != 0 && (
              <ul style={webStyles.menuBarStyleWrapperTop}>
                <li style={webStyles.menuBarStyle} onClick={() => { this.analyticTrack("analytics"); this.setState({ isClick: '/Analytics' }), this.changePath("/Analytics"); }}>
                  <img src={this.AnalyticsClick()}
                    style={{ width: 32, height: 32, objectFit: 'contain' }} />
                  <span style={{ marginLeft: 12, fontFamily: 'Rubik', fontSize: 14, fontWeight: "500", cursor: 'pointer', color: this.state.isClick === '/Analytics' ? '#6200EA' : '#676B7E', }}>Helios</span>
                </li>
              </ul>
            )
          }

          {
            this.state.sidebarPanelData
              .filter(item => ['All', 'Dashboards'].includes(item))
              .length != 0 && (
                <ul style={webStyles.menuBarStyleWrapperBottom}>
                  <li style={{ ...webStyles.menuBarStyle, cursor: 'pointer' }} onClick={() => this.expandFav()}>
                    <div style={{ width: 32, height: 32, alignItems: 'center', justifyContent: 'center', display: 'flex' }}><img src={star_border}
                      style={{ width: 32, height: 32, }} /></div>
                    <span style={{ marginLeft: 12, fontFamily: 'Rubik', fontSize: 14, fontWeight: "500", cursor: 'pointer', color: '#676B7E' }}>Starred</span>
                    {this.state.favouriteData?.length > 0 && <div style={{ width: 25, height: 25, alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: 65 }}>
                      <img src={!this.state.expandFavourite ? upArrow : downArrow}
                        style={{ width: '5.98px', height: '3.83px', }} /></div>}
                  </li>
                </ul>
              )
          }
          <div
            style={{
              paddingLeft: 35,
              display: 'flex',
              flexDirection: 'column',
              gap: 15,
            }}
          >
            {!this.state.expandFavourite && (
              this.state.favouriteData && this.state.favouriteData.map((item, index: number) => {
                return <a
                  key={index}
                  style={{
                    textDecoration: 'inherit',
                    fontFamily: 'Rubik',
                    fontSize: 12,
                    cursor: 'pointer',
                    color: '#676B7E',
                  }}
                  {...(
                    item.attributes.url_type === "Open In Same Tab" ?
                      { onClick: this.handleStarredClick(item) } :
                      { href: item.attributes.url, target: "_blank" }
                  )}
                >
                  {item.attributes.name}
                </a>
              }))}
          </div>
        </div>


        {
          this.state.showLogoutBtn && (
            <div onClick={this.handleLogout} style={{ boxShadow: '0px 8px 32px 0px #0000000F', padding: "3px", borderRadius: '8px', backgroundColor: '#fff', border: '1px solid #E2E8F0', position: "absolute", bottom: '76px', left: '28px', right: '10px', cursor: "pointer" }}>
              <div style={{ padding: "10px", backgroundColor: '#F0E5FF', borderRadius: '8px', display: 'flex', alignItems: 'center' }}>
                <img src={logout_icon} alt="User Avatar"
                  style={{ width: '16px', marginRight: '8px', marginLeft: '10px', }} />
                <span style={{ color: '#0F172A', fontSize: '14px', fontFamily: 'inter', lineHeight: '22px', fontWeight: 400 }}>Logout</span>
              </div>
            </div>
          )
        }


        <Box onClick={() => { this.analyticTrack("profile"); this.setState({ showLogoutBtn: !this.state.showLogoutBtn }); }} style={{
          display: "flex", alignItems: "center", marginBottom: 24, bottom: 0, cursor: "pointer",
          paddingTop: 15,
        }}>
          {this.state.userInfo.first_name ? <div style={{ borderRadius: '50%', width: 32, height: 32, backgroundColor: "#F3EBFF", alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: 14, fontWeight: "400", fontFamily: 'Rubik', color: '#676B7E' }}>{this.state.userInfo.first_name.charAt(0).toUpperCase()}</div> : <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" alt="User Avatar"
            style={{ width: 32, height: 32, borderRadius: 100, backgroundColor: "red", marginLeft: 12 }} />
          }
          <span style={{ fontSize: 14, fontWeight: "400", marginLeft: 12, fontFamily: 'Rubik', color: '#676B7E' }}>{this.state.userInfo.first_name} {this.state.userInfo.last_name}</span>
        </Box>
      </div>
    </div>
    )
  }

}


const webStyles = {
  menuBarStyleWrapperBottom: {
    listStyleType: 'none',
    padding: '0px',
  },
  menuBarStyleWrapperTop: {
    listStyleType: 'none',
    padding: '0px',
    marginTop: "25px",
  },
  menuBarStyle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
  },
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
